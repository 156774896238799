import { logout } from 'content/features/authentication/login/login.actions';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useSessionTimeout = (timeout) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user);

  useEffect(() => {
    if (!user) {
      return;
    }

    const handleSessionTimeout = () => {
      // eslint-disable-next-line
      dispatch(logout({ user: null, signedByGoogle: false }));
      alert('Tu sesión ha expirado por inactividad.');
    };

    const timeoutId = setTimeout(handleSessionTimeout, timeout);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId);
  }, [user, dispatch, timeout]);
};

export default useSessionTimeout;
