import { createReducer } from '@reduxjs/toolkit';

import BeneficiaryRequestsTypes from './beneficiaryRequests.types';

const initialState = {
  loaders: {
    initial: false,
  },
  controls: {
    error: false,
  },
  pagination: {
    page: 0,
    pageSize: 10,
    totalRequests: 0,
  },
  requests: [],
  blockCreateRequest: true,
};

const BeneficiaryRequestsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(BeneficiaryRequestsTypes.FETCH_REQUESTS, (state) => {
      state.loaders.initial = true;
      state.controls.error = false;
    })
    .addCase(BeneficiaryRequestsTypes.FETCH_REQUESTS_FAIL, (state) => {
      state.loaders.initial = false;
      state.controls.error = true;
    })
    .addCase(BeneficiaryRequestsTypes.FETCH_REQUESTS_SUCCESS, (state, action) => {
      state.loaders.initial = false;
      state.requests = action.payload.requests;
      state.pagination.totalRequests = action.payload.totalRequests;
      state.blockCreateRequest = action.payload.blockCreateRequest;
    })
    .addCase(BeneficiaryRequestsTypes.SET_PAGINATION, (state, action) => {
      state.pagination[action.payload.name] = action.payload.value;
    });
});

export default BeneficiaryRequestsReducer;
