import { call, put, spawn, takeLatest } from 'redux-saga/effects';

/* Project */
import api from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import {
  googleLogin,
  googleLoginFail,
  googleLoginSuccess,
  login,
  loginFail,
  loginSuccess,
  logout,
  logoutSuccess,
  resetControls,
  samlLogin,
  samlLoginSuccess,
} from './login.actions';

// eslint-disable-next-line complexity
function* loginUserApi(action) {
  try {
    const body = JSON.stringify(action.payload);
    const response = yield call(api, 'api/v1/auth/login', { method: 'post', body });

    if (!response.error) {
      if (!response.user.isEnabled) {
        // Usuario está deshabilitado, impedir el login
        yield put(
          loginFail('Este usuario está deshabilitado, por favor contacta al administrador.'),
        );
        defaultErrorToast({ message: 'Este usuario está deshabilitado.' });
        return;
      }
      localStorage.setItem(
        'user',
        JSON.stringify({
          email: response.user.email,
          userType: response.user.type,
        }),
      );
      window.location.href = '/login/mfa';
    } else {
      switch (response.statusCode) {
        case 401:
          yield put(loginFail('Por favor revise sus credenciales.'));
          break;
        case 429:
          yield put(loginFail('Demasiadas solicitudes, intenta nuevamente más tarde.'));
          break;
        default:
          yield put(loginFail('Error desconocido, por favor intenta nuevamente.'));
          break;
      }
      defaultErrorToast({ message: response.message });
    }
  } catch (error) {
    // Manejo de errores de red o del servidor
    yield put(loginFail('Error de red o servidor, por favor intenta nuevamente.'));
    defaultErrorToast({ message: 'Error de red o servidor, por favor intenta nuevamente.' });
  }
}

function* loginUser() {
  yield takeLatest(login, loginUserApi);
}

function* loginUserByGoogle() {
  yield takeLatest(googleLogin, function* fetchGoogleLogin(action) {
    const body = JSON.stringify(action.payload);

    const response = yield api('api/v1/auth/google', { method: 'post', body });

    if (!response.error) {
      if (!response.user.isEnabled) {
        // Usuario está deshabilitado, impedir el login
        yield put(
          loginFail('Este usuario está deshabilitado, por favor contacta al administrador.'),
        );
        defaultErrorToast({ message: 'Este usuario está deshabilitado.' });
        return; // Terminar la ejecución
      }
      localStorage.setItem(
        'user',
        JSON.stringify({
          token: response.token,
        }),
      );

      yield put(
        googleLoginSuccess({
          user: response.user,
        }),
      );
    } else {
      yield put(googleLoginFail());
      defaultErrorToast({ message: response.message });
    }
  });
}

const removeGoogleId = ({ user }) => {
  if (window.google) {
    window.google.accounts.id.revoke(user.email);
  }
};

function* logoutUser() {
  // eslint-disable-next-line complexity
  yield takeLatest(logout, function* logoutLocalUser({ payload }) {
    const { user, signedByGoogle } = payload;

    // Recuperar el token almacenado en el localStorage.user.token
    const token = JSON.parse(localStorage.getItem('user'))?.token;

    if (signedByGoogle) {
      removeGoogleId({ signedByGoogle, user });
    }

    if (token) {
      try {
        yield call(api, 'api/v1/auth/expired-tokens', {
          method: 'post',
          body: JSON.stringify({ token }),
        });
      } catch (error) {
        console.error('Error al guardar el token en ExpiredTokens:', error);
      }
    }

    localStorage.clear();
    yield put(resetControls());
    yield put(logoutSuccess());
  });
}

function* loginUserSaml() {
  yield takeLatest(samlLogin, function* samlLoginFunc(action) {
    const body = JSON.stringify(action.payload);
    const response = yield api('api/v1/auth/saml/login', { method: 'post', body });

    if (!response.error) {
      localStorage.setItem('user', JSON.stringify({ token: response.token }));

      yield put(loginSuccess({ user: response.user }));
      yield put(samlLoginSuccess(response));
    } else {
      yield put(loginFail());
      defaultErrorToast({ message: response.message });
    }
  });
}
function* verify2FAApi(action) {
  try {
    const body = JSON.stringify(action.payload); // { email, code }
    const response = yield call(api, 'api/v1/auth/verify-2fa', { method: 'post', body });

    if (!response.error) {
      // Guardamos el token en localStorage si el código es válido
      localStorage.setItem(
        'user',
        JSON.stringify({ token: response.token, userType: response.user.type }),
      );
      // Redirigimos al dashboard o página principal
      yield put(loginSuccess({ user: response.user }));
    } else {
      yield put(loginFail('Código de verificación incorrecto.'));
    }
  } catch (error) {
    yield put(loginFail('Error al verificar el código 2FA.'));
  }
}

function* verify2FAUser() {
  yield takeLatest('VERIFY_2FA', verify2FAApi);
}

export default function* LoginSaga() {
  yield spawn(loginUser);
  yield spawn(logoutUser);
  yield spawn(loginUserByGoogle);
  yield spawn(loginUserSaml);
  yield spawn(verify2FAUser);
}
